<template>
    <div class="result">
        <Head :title="$t('common.shaidan')" :right="true">
            <!-- <img @click="gotowhere('result-edit')" src="../../assets/imgs/edit.png" class="edit-icon" alt=""> -->
            <img @click="gotoResultList" src="../../assets/imgs/bei.png" class="bei-icon" alt="">
        </Head>
        <div class="main-box result-box">
            <div class="nav-main">
                <van-tabs :sticky="true" :animated="true" :swipeable="true" v-model="active" class="mytabs">
                    <van-tab name="1" title="Cloud Bid">
                        <TTList :changePList="changePList" :sortfunc="sortfunc" :changeParentList="changeParentList" :cb="true" :items="list.filter(item=>item.active_id==active)" ></TTList>
                    </van-tab>
                    <van-tab name="2"  title="Time Twister">
                        <TTList :changePList="changePList" :sortfunc="sortfunc" :changeParentList="changeParentList" :tt="true" :items="list.filter(item=>item.active_id==active)" ></TTList>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
    </div>
</template>

<script>
var id, fromname;
export default {
    name:'main-resultShai',
    data(){
        return {
            active:"1",
            list:[]
        }
    },
    beforeRouteLeave(to,from,next){
        next()
    },
    beforeRouteEnter(to,from,next){
        next()
        if(from.name=="goods"){
            id = from.hash.split("#")[1]
        }
    },
    
    mounted(){
        this.$ajax.shaidanist({
        })
        .then(res=>{
            if(res.code==200){
                this.list = res.data.list;
                this.$nextTick(()=>{
                })
            }
        })
    },
    activated(){
        if(id){
            console.log("activated-----"+id)
            // behavior: 'smooth'
            document.getElementById(id).scrollIntoView({ block: 'start', behavior: 'auto' })
        }  
    },
    deactivated(){
        id = null;
    },
    components:{

    },
    methods:{
        changePList(item){
            this.list = this.list.map(v=>{
                if(v.id==item.id){
                    v = {...v,is_zan:1}
                }
                return v;
            })
        },
        // a-b 升
        // b-a 降 
        sortfunc(flag,field){
            function sortable(a,b){
                if(field=="created_at"){
                    return  flag?new Date(a[field]).getTime()-new Date(b[field]).getTime():new Date(b[field]).getTime()-new Date(a[field]).getTime()
                }else{
                    return flag?a[field]-b[field] : b[field]-a[field]
                }
            }
            this.list = this.list.sort(sortable)
        },
        changeParentList(item){
            this.list = this.list.map(v=>{
                if(v.id==item.id){
                    v={...v,zan:v.zan+1,is_zan:1}
                }
                return v;
            });
        },
        gotoResultList(){
            this.$router.push({name:'jiexiao'})
        },
         gotowhere(name){
            this.$router.push({
                name
            })
        }
    }
}
</script>